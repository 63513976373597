import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { update_usuario } from "../../../store/users/registro/action";
import SaltarPaso from "../SaltarPaso";
import FormacionAcademica from "./FormacionAcademica";
import ExperienciaLaboral from "./ExperienciaLaboral";
import ExperienciaAcademica from "./ExperienciaAcademica";
import ExperienciaEspecialidad from "./ExperienciaEspecialidad";
import HabilidadesOfertaServicios from "./HabilidadesOfertaServicios";
import { useNavigate } from "react-router-dom";
import { validaDates } from "../../../helpers/formularios/Validation";
import { showFormErrors } from "../../../helpers/showErrors";
import CancelarModal from "../CancelarModal";
import { getModulo } from "../../../helpers/configRoutes/backend_helper";

const Acreditaciones = ({ setStep, item, setItem }) => {
    const [modal, setModal] = useState(false) 
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: item.acreditaciones,
        validationSchema: Yup.object({
            estudios: Yup.string().required('Campo requerido'),
            casa_estudios: Yup.string().required('Campo requerido'),
            cedula_profesional: Yup.string()
                .required('Campo requerido')
                .matches(/^\d{6,8}$/, 'La cedula debe tener entre 8 y 10 dígitos'),
            graduacion_fecha: validaDates(0, 'Tu graduacion tiene que ser anterior a la fecha de hoy '),
            descripcion_profesional: Yup.string().required('Campo requerido'),
            experiencias_laborales: Yup.array().of(
                Yup.object().shape({
                    cargo: Yup.string().required('Campo requerido'),
                    actividades: Yup.string().required('Campo requerido'),
                    fecha_inicio: validaDates(0, 'Tu Fecha de inicio tiene que ser anterior a la fecha de hoy '),
                    fecha_fin: Yup.date()
                        .required('Campo requerido')
                        .test('fecha-mayor', 'La fecha de término debe ser posterior a la fecha de inicio', function (value) {
                            const fechaInicio = this.parent.fecha_inicio;
                            return fechaInicio < value;
                        }),

                })
            ).min(1, 'Debe haber al menos una formación académica'),
            formacion_academica: Yup.array().of(
                Yup.object().shape({
                    institucion: Yup.string().required('Campo requerido'),
                    especialidad_id: Yup.number().required('Campo requerido'),
                    fecha_inicio: validaDates(0, 'Tu Fecha de inicio tiene que ser anterior a la fecha de hoy '),
                    fecha_fin: Yup.date()
                        .required('Campo requerido')
                        .test('fecha-mayor', 'La fecha de término debe ser posterior a la fecha de inicio', function (value) {
                            const fechaInicio = this.parent.fecha_inicio;
                            return fechaInicio < value;
                        }),
                    formacion: Yup.boolean(),
                })
            ).min(1, 'Debe haber al menos una formación académica'),
            formacion_especialidades: Yup.array().of(
                Yup.object().shape({
                    institucion: Yup.string().required('Campo requerido'),
                    especialidad_id: Yup.number().required('Campo requerido'),
                    fecha_inicio: validaDates(0, 'Tu Fecha de inicio tiene que ser anterior a la fecha de hoy '),
                    fecha_fin: Yup.date()
                        .required('Campo requerido')
                        .test('fecha-mayor', 'La fecha de término debe ser posterior a la fecha de inicio', function (value) {
                            const fechaInicio = this.parent.fecha_inicio;
                            return fechaInicio < value;
                        }),
                    formacion: Yup.boolean(),
                })
            ),
            servicios: Yup.array().min(1)
        }),
        onSubmit: async (values, { setErrors }) => {
            let newItem = { ...item, acreditaciones: values }
            dispatch(update_usuario(newItem))
            window.scrollTo(0, 500);
            setStep(3)
        },
    });

    const [modulo, setModulo] = useState(false);
    useEffect(() => {
        const getModulos = async () => {
            const response = await getModulo({ params: { modulo: 'Especialidades' } })
            setModulo(response.modulo)
        }
        getModulos()
    }, []);

    return (
        <>

            <SaltarPaso
                title={'Datos del perfil profesional'}
                text={'Ingresar tu información profesional es indispensable para ser contratado. Ésta te permitirá comprobar tu experiencia, conocimientos y habilidades ante nuestros clientes.'}
                textBold={''}
                textButton={'Saltar este paso y crear el CV después'}
                step={3}
                setStep={setStep}
            />
            <form
                onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    showFormErrors(validation)
                    return false;
                }}
            >
                <FormacionAcademica validation={validation} />
                <ExperienciaLaboral validation={validation} />
                <ExperienciaAcademica validation={validation} />
                {modulo.activo ? <ExperienciaEspecialidad validation={validation} />:''}
                <HabilidadesOfertaServicios validation={validation} />
                <div className="flex gap-3 text-2xl font-bold text-black max-md:flex-wrap mt-10">
                    <button
                        className="grow justify-center items-center px-16 py-5 shadow-sm bg-stone-100 rounded-[36px] w-fit max-md:px-5 max-md:max-w-full hover:bg-o2o-gris-hover"
                        onClick={() => {
                            setModal(true)
                        }}
                    >
                        Cancelar registro
                    </button>
                    <button
                        className="grow justify-center px-24 py-5 bg-o2o-aqua shadow-sm rounded-[36px] w-fit max-md:px-8 max-md:max-w-full hover:bg-o2o-aqua-hover"
                        type="submit"
                    >
                        Guardar información y continuar
                    </button>
                </div>
            </form>
            <CancelarModal modal={modal} setModal={setModal}/>
        </>
    )
}

export default Acreditaciones;